.right-pane {
    position: relative;
    background: black;
    color: #fff;
    width: 300px;
    height: 100vh;
    position: absolute;
    bottom: 0;
    right: 0;
    border-left: 1px solid grey;
  }
  .box1{
    
    width: 250px;
    height: 250px;
    margin: 50px 0px 10px 25px;
    border-radius: 6px;
    background: rgba(22, 24, 28,1);

   
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }

  .box2{
   
    width: 250px;
    height: 250px;
    margin: 0px 0px 10px 25px;
    border-radius: 6px;
    background: rgba(22, 24, 28,1);

  }