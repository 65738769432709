.home {
    background: black;
    color: #fff;
  }
.name{
  margin-left: 40px;
  margin-bottom: 15px;
  color: white;
}
  .profile{
    margin-left: 40px;
    border: 1px solid white;
    width: 650px;
    height: 400px;
  }
